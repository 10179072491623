import { doc, setDoc, getDoc, deleteDoc, serverTimestamp, collection, query, where, getDocs } from 'firebase/firestore';
import { generateRandomCode } from '../utils/codeGenerator';
import { db } from '../firebase';
import { auth } from '../firebase';
import { getDeviceId } from '../utils/deviceIdentifier';
import { signInWithCustomToken } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Generate a linking code for a device
export const generateLinkingCode = async (deviceId) => {
  try {
    // Ensure we have a consistent deviceId
    const consistentDeviceId = await getDeviceId();
    
    const code = generateRandomCode(6);
    console.log('Generated code for device:', consistentDeviceId);
    
    await setDoc(doc(db, 'linking_codes', code), {
      deviceId: consistentDeviceId,  // Use the consistent deviceId
      createdAt: new Date(),
      used: false
    });

    return code;
  } catch (error) {
    console.error('Error generating linking code:', error);
    throw error;
  }
};

// Function to link an Adhan Clock device to a user
export const linkDevice = async (code) => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error('No user logged in');

    // Get the linking code document
    const linkingCodeRef = doc(db, 'linking_codes', code);
    const linkingCodeDoc = await getDoc(linkingCodeRef);
    if (!linkingCodeDoc.exists()) throw new Error('Invalid linking code');

    // Get the deviceId from the linking code
    const { deviceId: fingerprintDeviceId } = linkingCodeDoc.data();
    console.log('Linking Adhan Clock device with fingerprint ID:', fingerprintDeviceId);

    // Create device link with fingerprint deviceId - only in device_links for Adhan Clocks
    const deviceLinkRef = doc(db, 'device_links', fingerprintDeviceId);
    await setDoc(deviceLinkRef, {
      userId,
      linkedAt: serverTimestamp(),
      deviceId: fingerprintDeviceId,  // Store it in the document too
      deviceType: 'adhan_clock'  // Add device type to distinguish from TVs
    });

    // Update user settings with the correct deviceId
    const userSettingsRef = doc(db, 'userSettings', userId);
    const settingsDoc = await getDoc(userSettingsRef);
    await setDoc(userSettingsRef, {
      ...(settingsDoc.exists() ? settingsDoc.data() : {}),
      deviceId: fingerprintDeviceId  // Use fingerprint ID here too
    }, { merge: true });

    // Delete the linking code
    await deleteDoc(linkingCodeRef);

    return { success: true, deviceId: fingerprintDeviceId };
  } catch (error) {
    console.error('Error linking Adhan Clock device:', error);
    throw error;
  }
};

export const requestLinkingCode = async (deviceId) => {
  try {
    console.log('Requesting linking code for device:', deviceId);
    
    // First check if device document exists in Firestore
    const deviceDoc = await getDoc(doc(db, 'device_links', deviceId));
    
    // Add debug logging
    console.log('Device link status:', {
      exists: deviceDoc.exists(),
      deviceId,
      data: deviceDoc.exists() ? deviceDoc.data() : null
    });

    if (!deviceDoc.exists()) {
      console.log('Device is not linked, requesting new code');
      // Device is not linked, proceed with getting a code
      const response = await fetch(`${API_BASE_URL}/tv/request-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ device_id: deviceId })
      });

      if (!response.ok) {
        throw new Error(`Failed to get linking code: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received linking code response:', data);
      
      return {
        isLinked: false,
        linkingCode: data.linking_code,
        expiresAt: data.expires_at ? new Date(data.expires_at) : null
      };
    } else {
      // Device is linked, check if we already have a valid auth token
      if (auth.currentUser) {
        console.log('Device already authenticated, skipping token request');
        return { isLinked: true };
      }

      // Only request new token if not already authenticated
      console.log('Device is linked but not authenticated, getting auth token');
      const tokenResponse = await fetch(`${API_BASE_URL}/auth-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: deviceId })
      });

      if (!tokenResponse.ok) {
        throw new Error('Failed to get auth token');
      }

      const tokenData = await tokenResponse.json();
      await signInWithCustomToken(auth, tokenData.token);
      
      return { isLinked: true };
    }
  } catch (error) {
    console.error('Error requesting linking code:', error);
    throw error;
  }
};

// Get all linked Adhan Clock devices for current user
export const getLinkedDevices = async () => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error('No user logged in');

    // Create two queries: one for devices with type=adhan_clock and one for legacy devices
    const typeQuery = query(
      collection(db, 'device_links'),
      where('userId', '==', userId),
      where('deviceType', '==', 'adhan_clock')
    );

    // Query for legacy devices that don't have a deviceType field
    // We need to get all user's devices and filter out any that have a deviceType that isn't adhan_clock
    const legacyQuery = query(
      collection(db, 'device_links'),
      where('userId', '==', userId)
    );

    // Execute both queries
    const [typeQuerySnapshot, legacyQuerySnapshot] = await Promise.all([
      getDocs(typeQuery),
      getDocs(legacyQuery)
    ]);

    // Get devices with adhan_clock type
    const adhanClockDevices = typeQuerySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Get legacy devices (without deviceType field)
    const legacyDevices = legacyQuerySnapshot.docs
      .filter(doc => !doc.data().deviceType) // Only include those without deviceType
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

    // Log for debugging
    console.log(`Found ${adhanClockDevices.length} adhan clock devices and ${legacyDevices.length} legacy devices`);

    // Combine both sets of devices
    return [...adhanClockDevices, ...legacyDevices];
  } catch (error) {
    console.error('Error getting linked devices:', error);
    throw error;
  }
};

// Unlink a device
export const unlinkDevice = async (deviceId) => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error('No user logged in');

    console.log('Attempting to unlink Adhan Clock device:', deviceId);

    // Verify ownership before deleting
    const deviceDoc = await getDoc(doc(db, 'device_links', deviceId));
    if (!deviceDoc.exists()) {
      console.log('Device link not found for:', deviceId);
      throw new Error('Device link not found');
    }
    
    if (deviceDoc.data().userId !== userId) {
      console.log('Unauthorized unlink attempt:', {
        docUserId: deviceDoc.data().userId,
        currentUserId: userId
      });
      throw new Error('Not authorized to unlink this device');
    }

    // Only delete from device_links collection for Adhan Clock devices
    console.log('Deleting Adhan Clock device link document:', deviceId);
    await deleteDoc(doc(db, 'device_links', deviceId));
    
    console.log('Adhan Clock device successfully unlinked:', deviceId);
    
    return { success: true };
  } catch (error) {
    console.error('Error unlinking Adhan Clock device:', error);
    throw error;
  }
}; 