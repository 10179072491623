import React, { useState, useEffect, useRef } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import './CrossfadingBackground.css';

const CrossfadingBackground = () => {
  const { settings } = useSettings();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1400);
  const [loadedUrls, setLoadedUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [failedUrls, setFailedUrls] = useState({});

  // Extract background images from settings; fallback to empty array
  const images = settings.simpleDashboard?.backgroundImages || [];

  // Extract Firebase Storage path from URL or use direct path
  const extractStoragePath = (input) => {
    if (!input) return '';
    
    // If it's not a URL, assume it's already a storage path
    if (!input.startsWith('http')) return input;
    
    try {
      // Handle different URL formats from Firebase Storage
      if (input.includes('firebasestorage.googleapis.com')) {
        // Format: https://firebasestorage.googleapis.com/v0/b/PROJECT.appspot.com/o/PATH?alt=media&token=TOKEN
        const url = new URL(input);
        const pathParam = url.pathname.split('/o/')[1];
        if (pathParam) {
          return decodeURIComponent(pathParam);
        }
      } else if (input.includes('storage.googleapis.com')) {
        try {
          // Extract the path without query parameters
          const basePath = input.split('storage.googleapis.com/')[1];
          if (!basePath) return input;
          
          // Find project bucket and remove it
          const firstSlashIndex = basePath.indexOf('/');
          if (firstSlashIndex === -1) return input;
          
          // Extract everything after the first slash (the actual path)
          const path = basePath.substring(firstSlashIndex + 1);
          
          // Remove any query parameters
          const queryIndex = path.indexOf('?');
          const cleanPath = queryIndex !== -1 ? path.substring(0, queryIndex) : path;
          
          console.log('Extracted path from storage.googleapis.com URL:', cleanPath);
          return cleanPath;
        } catch (err) {
          console.warn('Error extracting path from storage.googleapis.com URL:', err);
          return input;
        }
      }
      
      // If we couldn't extract a path, return the original input
      console.warn("Couldn't extract storage path from URL:", input);
      return input;
      
    } catch (error) {
      console.warn("Error extracting path from URL:", error);
      return input;
    }
  };

  // Get a fresh URL using various methods, with fallbacks
  const convertPathToUrl = async (image) => {
    // Track the original image for debugging
    const originalImage = typeof image === 'string' ? image : JSON.stringify(image);
    
    // Extract the path and fallback URL
    let storagePath = '';
    let fallbackUrl = '';
    
    if (typeof image === 'string') {
      // If image is a string, it could be either a path or URL
      storagePath = !image.startsWith('http') ? image : extractStoragePath(image);
      fallbackUrl = image.startsWith('http') ? image : '';
    } else if (image && typeof image === 'object') {
      // If image is an object, prioritize path properties
      storagePath = image.path || image.originalPath || image.displayPath || image.thumbnailPath || '';
      
      // Set fallback URL from URL properties
      fallbackUrl = image.url || image.original || image.display || '';
    }
    
    if (!storagePath && !fallbackUrl) {
      console.warn('No valid path or URL found for image:', originalImage);
      return '';
    }
    
    // First try to use the storage path to get a fresh URL
    if (storagePath) {
      try {
        // Skip invalid paths
        if (storagePath === 'user_images') {
          console.warn('Invalid or incomplete storage path:', storagePath);
          if (fallbackUrl) return fallbackUrl;
          return '';
        }
        
        console.log('Getting fresh URL for path:', storagePath);
        
        // Get a fresh URL from Firebase using the storage path
        const storage = getStorage();
        const storageRef = ref(storage, storagePath);
        return await getDownloadURL(storageRef);
      } catch (error) {
        console.error('Error getting fresh URL from path:', storagePath, error);
        
        // If we have a fallback URL, use it
        if (fallbackUrl) {
          console.log('Using fallback URL instead:', fallbackUrl);
          return fallbackUrl;
        }
        
        return '';
      }
    }
    
    // If we don't have a storage path but do have a fallback URL, use that
    if (fallbackUrl) {
      console.log('Using pre-existing URL:', fallbackUrl);
      return fallbackUrl;
    }
    
    return '';
  };

  // Get the best image source based on screen size and available properties
  const getImageSource = (image) => {
    if (!image) return null;
    
    // If image is a string, return it directly
    if (typeof image === 'string') return image;
    
    // Create an object with all important properties for conversion
    const imageData = { ...image };
    
    // Determine which path to prioritize based on screen size
    if (isLargeScreen) {
      // For large screens: prefer the original/full size
      imageData.priorityPath = image.originalPath || image.path;
      imageData.priorityUrl = image.original || image.url;
    } else {
      // For smaller screens: prefer display or thumbnail
      imageData.priorityPath = image.displayPath || image.thumbnailPath || image.path || image.originalPath;
      imageData.priorityUrl = image.display || image.thumbnail || image.url || image.original;
    }
    
    return imageData;
  };

  // Load URLs for all images when component mounts or images change
  useEffect(() => {
    const loadAllImageUrls = async () => {
      if (images.length === 0) {
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      const urls = [];
      
      console.log(`Loading ${images.length} background images...`);
      let successCount = 0;
      const errors = {};
      
      for (let i = 0; i < images.length; i++) {
        try {
          const imageSource = getImageSource(images[i]);
          
          if (imageSource) {
            console.log(`Processing image ${i+1}/${images.length}`);
            const url = await convertPathToUrl(imageSource);
            
            if (url) {
              urls.push(url);
              successCount++;
            } else {
              console.error(`Failed to get URL for image ${i+1}`);
              errors[i] = { image: imageSource, error: 'No URL returned' };
            }
          } else {
            console.error(`Invalid image source at index ${i+1}`);
            errors[i] = { image: images[i], error: 'Invalid image source' };
          }
        } catch (error) {
          console.error(`Error processing image ${i+1}:`, error);
          errors[i] = { image: images[i], error: error.message };
        }
      }
      
      setFailedUrls(errors);
      console.log(`Successfully loaded ${successCount} background images`);
      setLoadedUrls(urls);
      setIsLoading(false);
    };
    
    loadAllImageUrls();
  }, [images, isLargeScreen]);

  // Handle screen size changes
  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth >= 1400);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Get duration from settings
  const duration = settings.simpleDashboard?.slideshowDuration || 60;
  const fadeDuration = settings.simpleDashboard?.slideshowFadeDuration || 1000;

  if (images.length === 0 || isLoading || loadedUrls.length === 0) {
    console.log("Not rendering background - conditions not met:", {
      imagesLength: images.length,
      isLoading,
      loadedUrlsLength: loadedUrls.length
    });
    return null;
  }

  // If only one image, just render it statically
  if (loadedUrls.length === 1) {
    return (
      <div className="background-container">
        <img 
          src={loadedUrls[0]} 
          className="single-background-image" 
          alt="Background"
        />
      </div>
    );
  }

  return (
    <div className="background-container">
      <Swiper
        modules={[EffectFade, Autoplay]}
        effect="fade"
        fadeEffect={{ 
          crossFade: true // Enable crossfade effect
        }}
        speed={fadeDuration} // Transition speed in ms
        autoplay={{
          delay: duration * 1000, // Convert to milliseconds
          disableOnInteraction: false,
        }}
        loop={true}
        className="background-swiper"
      >
        {loadedUrls.map((url, index) => (
          <SwiperSlide key={index} className="background-slide">
            <img 
              src={url} 
              alt={`Background ${index + 1}`} 
              className="background-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CrossfadingBackground; 